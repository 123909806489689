



import React from 'react'
import { useDispatch } from 'react-redux';
import { useDeleteCategoryMutation } from 'services/api';
import { closeModal } from 'store/slice/commonSlice';

export default function DeleteCategory({ category, id }) {
    

    // console.log(category, id)

    const dispatch = useDispatch()
    const [deleteCategory, {isLoading, isSuccess}] = useDeleteCategoryMutation()

    function handleCloseDeleteModal() {
        dispatch(closeModal())
    }

    const handleDeleteCategory = () => {
        
        deleteCategory({
            id: id
        })
            .unwrap()
            .then(data => console.log(data))
        .catch(error => console.log(error))
    }

    if (isLoading) {
        console.log("Loading delete category")
    }

    if (isSuccess) {
        console.log("It's a success")
    }
    
    

    return (
      <div className="flex flex-col items-center justify-center">
        <div className="w-20 h-20 bg-slate-100 p-5 rounded-full mb-5">
          <img src="/delete-icon.png" />
        </div>
        <h1 className="px-20 text-center leading-tight text-xl">
          Are you sure you want to delete the{" "}
          <span className="font-bold">{category} </span>
          category
        </h1>
        <div>
          <div className="flex items-center space-x-5 mt-7">
            <button
              className="text-amber-700 px-5 py-2 rounded-md min-w-[150px]"
              onClick={handleCloseDeleteModal}
            >
              Cancel
            </button>
            <button
              className="bg-red-700 text-white px-5 py-2 rounded-md min-w-[150px]"
              onClick={handleDeleteCategory}
            >
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    );
}
