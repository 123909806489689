import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "services/api";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import commonSlice from "./slice/commonSlice";
import postSlice from "./slice/postSlice";
import summarySlice from "./slice/summarySlice";
import { authReducer } from "./slice/authSlice";

const api = apiSlice.middleware;



const rootReducer = combineReducers({
  auth: authReducer,
  summary: summarySlice,
  post: postSlice,
  common: commonSlice,
  api: apiSlice.reducer
})

const persistConfig = {
  key: "root",
  storage,
  version: 1,
  blacklist: ['api']
};

const persistedStore = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedStore,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
