

import React from 'react'

export default function SingleTransaction() {
    return (
        <div>
            <h1>Single Transaction</h1>
        </div>
    )
}
