import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "store/slice/commonSlice";


export default function ModalWrapper({ children }) {


    const dispatch = useDispatch()

    const open = useSelector(state => state.common.modalState)
    console.log("Modal state", open)
    

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // function openModal() {
  //   setOpen(true);
  // }

  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  // }

  function handleCloseModal() {
    dispatch(closeModal())
  }

  return (
    <div>
      {/* <Button onClick={handleCloseModal}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal>
    </div>
  );
}
