import { createSlice } from "@reduxjs/toolkit";
import { FcBusinessman, FcHome } from "react-icons/fc";

const initialState = [
  {
    name: "Total Vendor",
    value: 9000,
    // icon: <FcBusinessman className="text-6xl" />,
    link: "user",
  },
  {
    name: "Total Customers",
    value: 500,
    // icon: <FcHome className="text-6xl" />,
    link: "user",
  },
  {
    name: "Total Trades",
    value: 4500,
    // icon: <FcHome className="text-6xl" />,
    link: "report",
  },
  {
    name: "Total Listed Products",
    value: 4500,
    // icon: <FcHome className="text-6xl" />,
    link: "",
  },
];

const summarySlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    postAdded(state, action) {
      state.push(action.payload);
    },
  },
});

export const { postAdded } = summarySlice.actions;

export default summarySlice.reducer;
