



import React, { useEffect, useState } from 'react'
import {ColorRing } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginAdmin, startLoading } from 'store/slice/authSlice';




export default function Home() {

  

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // state details
  const authUser = useSelector(state => state.auth.user)
  const error = useSelector(state => state.auth.error)
  const loadingState = useSelector(state => state.auth.loading)
  console.log("auth details", authUser, error, loadingState)
  // const {user} = authUser
  // console.log(user.data.token)




    const [detail, setDetail] = useState({
      email: "",
      password: "",
    });
  // const [loadingState, setLoadingState] = useState(false)
  
  const user = useSelector(state => state.auth)
  console.log(user)
  
  // useEffect(() => {

  //     const token = sessionStorage.getItem("afcfta-user")
  //   if (token && token !== "undefined") {
  //     navigate("/dashboard")
  //   }
    
  // }, [])

  // const tin = sessionStorage.getItem("afcfta-user")
  // console.log(JSON.parse(tin));
  
  
  

    function inputChangeHandler(e) {
      const { name, value } = e.target;
      console.log(name, value);
      setDetail({ ...detail, [name]: value });
    }

    function handleSubmitLogin(e) {
      e.preventDefault()
      
        const { email, password } = detail
      console.log("sending data now")
      if (email && password) {
        dispatch(loginAdmin({ email, password }));
        console.log('data sent')
      }      
    }
  

  

    return (
      <div className="h-screen flex flex-col items-center">
        <div
          className="h-20 w-full bg-slate-500 relative flex items-center text-white"
          style={{
            backgroundImage: `url(/barground.svg)`,
            //   width: "100%",
            //   height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "100%  50%",
          }}
        >
          <h1 className="text-3xl font-bold px-10">Admin Portal</h1>
        </div>

        <div className="flex justify-center">
          <div className="bg-[#664820] w-32 h-32 rounded-full mt-14">
            <img src="/afcfta-logo.png" className="bg-contain " />
          </div>
        </div>
        <div className="flex justify-center mt-7">
          <h1 className="text-3xl font-bold px-10 text-center">Admin Login</h1>
        </div>

        {/* login component */}
        <div className="mt-10">
          <form className="flex flex-col space-y-3">
            <input
              className="w-[350px] py-2 bg-transparent border border-slate-400 rounded-md placeholder-slate-300 text-center"
              placeholder="Email/Username"
              type="email"
              name="email"
              value={detail.email}
              onChange={inputChangeHandler}
            />
            <input
              className="w-[350px] py-2 bg-transparent border border-slate-400 rounded-md placeholder-slate-300 text-center"
              placeholder="password"
              type="password"
              name="password"
              value={detail.password}
              onChange={inputChangeHandler}
            />

            {/* <Link to="/dashboard"> */}
            <button
              className="w-[350px] py-2 bg-amber-700 text-white rounded-md"
              onClick={handleSubmitLogin}
            >
              Login
            </button>
            {/* </Link> */}
          </form>
        </div>

        {/* Loader */}
        <div className="mt-10">
          {loadingState && (
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          )}
        </div>
      </div>
    );
}

