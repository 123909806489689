





import React from 'react'
import {
  FcBinoculars,
  FcBusinessman,
  FcConferenceCall,
  FcHome,
  FcStackOfPhotos,
} from "react-icons/fc"
import { Link } from 'react-router-dom';

export default function SidebarMenu() {
    return (
      <div className="flex flex-col">
        <Link to="/dashboard/">
          <button className="bg-amber-50 border-l-[6px] border-amber-800 text-lg target:bg-blue-600 target:text-white hover:bg-amber-400 px-2.5 w-full py-4  flex items-center gap-2 font-semibold">
            <span>
              <FcHome className="text-2xl" />
            </span>
            Dashboard
          </button>
        </Link>
        <Link to="admin">
          <button className="bg-amber-50 border-l-[6px] border-amber-800 text-lg hover:bg-amber-400 px-2.5 w-full py-4  flex items-center gap-2 font-semibold">
            <span>
              <FcBusinessman className="text-2xl" />
            </span>
            Admin
          </button>
        </Link>
        <Link to="user">
          <button className="bg-amber-50 border-l-[6px] border-amber-800 text-lg hover:bg-amber-400 px-2.5 w-full py-4  flex items-center gap-2 font-semibold">
            <span>
              <FcBusinessman className="text-2xl" />
            </span>
            User
          </button>
        </Link>
        <Link to="transaction">
          <button className="bg-amber-50 border-l-[6px] border-amber-800 text-lg hover:bg-amber-400 px-2.5 w-full py-4  flex items-center gap-2 font-semibold">
            <span>
              <FcConferenceCall className="text-2xl" />
            </span>
            Transactions
          </button>
        </Link>
        <Link to="category">
          <button className="bg-amber-50 border-l-[6px] border-amber-800 text-lg hover:bg-amber-400 px-2.5 w-full py-4  flex items-center gap-2 font-semibold">
            <span>
              <FcStackOfPhotos className="text-2xl" />
            </span>
            Categories
          </button>
        </Link>
        <Link to="report">
          <button className="bg-amber-50 border-l-[6px] border-amber-800 text-lg hover:bg-amber-400 px-2.5 w-full py-4  flex items-center gap-2 font-semibold">
            <span>
              <FcBinoculars className="text-2xl" />
            </span>
            Report
          </button>
        </Link>
      </div>
    );
}
