import logo from './logo.svg';
import './App.css';
import MainRoute from './route/MainRoute';




function App() {
  return (
    
      <div className="">
        <MainRoute />
      </div>
  );
}

export default App;
