


import React, { useState } from 'react'
import { MdDelete } from "react-icons/md"
import Modal from "react-modal"
import { useLocation, useParams } from 'react-router-dom';
import { useGetSingleUserQuery } from 'services/api';
import Accordion from './child/Accordion';

export default function SingleUserDetails() {



    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        width: "450px"
      },
    }; 

  
  
  const {id} = useParams()
  // console.log(id)


    const [modalOpen, setModalOpen] = React.useState(false)


    function openModal() {
        setModalOpen(true)
    }

    function afterOpenModal() {
        console.log("after open modal")
    }

    function closeModal() {
        setModalOpen(false)
    }


  // Data
  const {data, error, isLoading} = useGetSingleUserQuery(id)
  
  if (isLoading) {
    console.log("Loading")
  }
  if (error) {
    console.log(error)
  }
  if (data) {
    console.log("Single user data", data)
  }

  const userDetail = data ? data.data : {}
  console.log(userDetail)
  
    return (
      <div className="px-5 mb-32">
        <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          style={customStyles}
          onAfterClose={afterOpenModal}
          ariaHideApp={false}
        >
          <div className="flex justify-end mb-10">
            <button onClick={closeModal}>x Close</button>
          </div>
          <div className="flex flex-col items-center space-y-5">
            <h1 className="text-2xl text-center font-semibold">
              Are you sure you want to perform this action?
            </h1>
            <button className="w-full bg-red-700 text-white py-2.5 rounded-lg">
              Yes, Delete
            </button>
            <button className="text-lg font-medium" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </Modal>

        <div className="mt-5 flex justify-between items-center">
          <h1>User Information</h1>
          <div className="flex space-x-5">
            <button>Update</button>
            <button
              className="px-10 py-1.5 rounded-lg text-red-500 flex items-center space-x-1"
              onClick={openModal}
            >
              <span>
                <MdDelete />
              </span>{" "}
              <p>Delete</p>
            </button>
          </div>
        </div>
        <hr className="border-slate-200 mt-1" />

        {/* user information */}

        <div className="flex mt-5">
          <img src="/user-profile.png" className="" />
          <div className="flex flex-col space-y-1 px-10 py-5">
            <h1 className="font-bold text-3xl">{userDetail.fullName}</h1>
            <p className="text-lg">Role: {userDetail.userType}</p>
          </div>
        </div>

        <div className="mt-10">
          {/* {fieldData.map((field, i) => ( */}
          <div className="grid grid-cols-8 gap-1">
            <div className="col-span-2">
              <p>firstName</p>
            </div>
            <div className="col-span-6">
              <p className="font-bold">{userDetail.firstName}</p>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-1">
            <div className="col-span-2">
              <p>LastName</p>
            </div>
            <div className="col-span-6">
              <p className="font-bold">{userDetail.lastName}</p>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-1">
            <div className="col-span-2">
              <p>Address</p>
            </div>
            <div className="col-span-6">
              <p className="font-bold">{userDetail.lastName}</p>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-1">
            <div className="col-span-2">
              <p>Mobile Phone</p>
            </div>
            <div className="col-span-6">
              <p className="font-bold">{userDetail.phoneNumber}</p>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-1">
            <div className="col-span-2">
              <p>Email Address</p>
            </div>
            <div className="col-span-6">
              <p className="font-bold">{userDetail.email}</p>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-1">
            <div className="col-span-2">
              <p>Status</p>
            </div>
            <div className="col-span-6">
              <p className="font-bold">{userDetail.active === true ? `Active` : `Inactive`}</p>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-1">
            <div className="col-span-2">
              <p>Country</p>
            </div>
            <div className="col-span-6">
              <p className="font-bold">{userDetail.country}</p>
            </div>
          </div>
          {/* ))} */}
        </div>

        <div className="flex items-center mt-5 space-x-4">
          <p>Documents</p>
          <hr className="border-slate-200 mt-1 w-full" />
        </div>

        <div className="flex items-center mt-5 space-x-4">
          <button className="bg-amber-800 rounded-full py-1.5 px-3.5 text-white font-bold">
            National ID
          </button>
          <button className="bg-amber-800 rounded-full py-1.5 px-3.5 text-white font-bold">
            Business Reg Cert.
          </button>
        </div>

        <div className="flex items-center my-5 space-x-4">
          <p className="whitespace-nowrap font-bold">Listed Products</p>
          <hr className="border-slate-200 mt-1 w-full" />
        </div>

        <Accordion />
      </div>
    );
}










const fieldData = [
  {
    field: "First name",
    value: "John",
  },
  {
    field: "Last name",
    value: "Biko",
  },
  {
    field: "Business name",
    value: "Bikengo Ltd",
  },
  {
    field: "Address",
    value: "Suite 9D, PalmOath Vaenue Str, MDN",
  },
  {
    field: "Mobile no",
    value: "+236 894 576 1234",
  },
  {
    field: "Email address",
    value: "johnthebiko@gmail.com",
  },
  {
    field: "Status",
    value: "Active",
  },
  {
    field: "Country",
    value: "Madagascar",
  },
];