


import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import { Avatar } from '@mui/material';
import { logoutAdmin } from 'store/slice/authSlice';

export default function DashboardIndex() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth)
  // console.log(user)

  const { firstName, fullName, LastName, profilePhoto } = user && user
  // const userType = user && user.user
  
  // useEffect(() => {
  //   const token = sessionStorage.getItem("afcfta-user");
  //   if (!token.startsWith('ey')) {
  //     navigate("/");
  //   }
  // }, [])

  function handleLogoutUser () {
    dispatch(logoutAdmin())
  }
  



    return (
      <div>
        <div className="grid grid-cols-12 mb-7 mt-4">
          {/* Header */}
          <div className="col-span-2 flex items-center justify-center">
            <div className="w-12 h-12">
              <Link to="/">
                <img src="/afcfta-logo.png" className="bg-contain" />
              </Link>
            </div>
          </div>
          <div className="col-span-10 flex items-center justify-end w-full px-10 h-14">
            <p>{""}</p>
            <div className="h-9 w-9 mx-2.5 rounded-full bg-green-400" onClick={handleLogoutUser}>
              
              <Avatar src='' />
              
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-12 grid-cols-1">
          <div className="col-span-2 lg:min-h-[80vh] hidden md:block">
            <div className="flex flex-col">
              <SidebarMenu />
            </div>
          </div>
          <div className="col-span-10 px-10">
            <div
              className="h-20 flex justify-between items-center p-5 rounded"
              style={{
                backgroundImage: `url(/barground.svg)`,
                //   width: "100%",
                //   height: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "100%  50%",
              }}
            >
              <h1 className="text-white text-2xl font-bold">
                Super Admin Portal
              </h1>
              <div className="bg-red-700 px-5 py-2 rounded-xl">
                <h1 className="text-white italic">{""}</h1>
              </div>
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    );
}
