



import ModalWrapper from 'components/common/ModalWrapper'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { AddCateogryModal, openModal } from 'store/slice/commonSlice'
import AddCategory from './child/AddCategory'

export default function CateogoryIndex() {

  const dispatch = useDispatch()


  const location = useLocation()
  
  // category modal details state
  const newCategory = useSelector(state => state.common.newCategory)
  

  // console.log(newCategory)

  const handleAddCategoryModal = () => {
    dispatch(openModal())
    dispatch(AddCateogryModal())
    
  }



    return (
      <div>
        <ModalWrapper>{newCategory && <AddCategory />}</ModalWrapper>
        {location.pathname === "/dashboard/product" && (
          <div className="flex items-center space-x-5 mt-10">
            <button
              className="bg-amber-700 text-white px-5 py-2 rounded-md"
              onClick={handleAddCategoryModal}
            >
              Add New Category
            </button>
            <button className="bg-amber-700 text-white px-5 py-2 rounded-md">
              View Classifications
            </button>
          </div>
        )}

        <Outlet />
      </div>
    );
}
