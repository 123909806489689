



import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function ReportDetails() {


    const navigate = useNavigate()

    function handleRowClick(i, data) {
      console.log(i);
      navigate(`/dashboard/report/${i}`)
    }

    return (
      <div>
        <div className="mt-10">
          <div className=" overflow-y-scroll relative shadow-md rounded-md mt-10 max-h-[420px]">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="py-3 px-3">
                    S/N
                  </th>
                  <th scope="col" className="py-3 px-3">
                    Name
                  </th>
                  {/* <th scope="col" className="py-3 px-3">
                  ID
                </th> */}
                  <th scope="col" className="py-3 px-6">
                    Id
                  </th>
                </tr>
              </thead>
              <tbody>
                {testData.map((user, i) => (
                  <tr
                    className="bg-white border-b group"
                    onClick={() => handleRowClick(i, user)}
                  >
                    <th
                      scope="row"
                      className="py-4 px-3 font-medium text-gray-900 whitespace-nowrap group-hover:bg-blue-600 group-hover:text-white"
                    >
                      {i + 1}
                    </th>
                    <td className="py-4 px-3 group-hover:bg-blue-600 group-hover:text-white">
                      {user.name}
                    </td>
                    {/* <td className="py-4 px-3 group-hover:bg-blue-600 group-hover:text-white">
                    {user.id}
                  </td> */}
                    <td className="py-4 px-6 group-hover:bg-blue-600 group-hover:text-white">
                      <a
                        href="#"
                        className="font-medium text-blue-600 hover:text-white hover:underline group-hover:bg-blue-600 group-hover:text-white"
                      >
                        {user.id}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
}



const min = 10000;
const max = 99999;

const testData = [
  {
    name: "Hassan Amir",
    road: 2,
    house: 14,
    id: `NG${Math.floor(Math.random() * (max - min + 1029000123458)) + min}`,
  },
  {
    name: "Chukwu Emke",
    road: 2,
    house: 14,
    id: `GH${Math.floor(Math.random() * (max - min + 1029000123458)) + min}`,
  },
  {
    name: "Chukwu Emke",
    road: 2,
    house: 14,
    id: `GH${Math.floor(Math.random() * (max - min + 1029000123458)) + min}`,
  },
];