

import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../components/homepage/Home'
import DashboardIndex from '../components/dashboard/DashboardIndex'
import Summary from '../components/dashboard/Summary'
import UserIndex from '../components/dashboard/user/UserIndex'
import UserList from '../components/dashboard/user/UserList'
import SingleUserDetails from '../components/dashboard/user/SingleUserDetails'
import ReportIndex from '../components/dashboard/report/ReportIndex'
import ReportDetails from '../components/dashboard/report/ReportDetails'
import SingleReport from '../components/dashboard/report/SingleReport'
// import ReportPDFPage from '../components/dashboard/report/ReportPDFPage'
import SingleTransaction from '../components/dashboard/transaction/SingleTransaction'
import AdminList from '../components/dashboard/admin/AdminList'
import SingleAdmin from '../components/dashboard/admin/SingleAdmin'
import TransactionIndex from '../components/dashboard/transaction/TransactionIndex'
import ProductIndex from '../components/dashboard/category/CategoryIndex'
import ProductList from '../components/dashboard/category/CategoryList'
import SingleProduct from '../components/dashboard/category/SingleCategory'
import CateogoryIndex from '../components/dashboard/category/CategoryIndex'
import CategoryList from '../components/dashboard/category/CategoryList'


export default function MainRoute() {
    return (
      <div>
        <Routes>
          <Route path="/" element={<Home />} />

          {/* admin page */}
          <Route path="dashboard" element={<DashboardIndex />}>
            {/* user */}
            <Route path="/dashboard/" element={<Summary />} />

            <Route path="admin" element={<UserIndex />}>
              <Route path="/dashboard/admin/" element={<AdminList />} />
              <Route path=":id" element={<SingleAdmin />} />
            </Route>

            <Route path="user" element={<UserIndex />}>
              <Route path="/dashboard/user/" element={<UserList />} />
              <Route path=":id" element={<SingleUserDetails />} />
            </Route>
            <Route path="transaction" element={<TransactionIndex />}>
              {/* <Route path="/dashboard/transaction/" element={<UserList />} /> */}
              <Route path=":id" element={<SingleTransaction />} />
            </Route>

            <Route path="category" element={<CateogoryIndex />}>
              <Route path="/dashboard/category/" element={<CategoryList />} />
              <Route path=":id" element={<SingleProduct />} />
            </Route>

            <Route path="report" element={<ReportIndex />}>
              <Route path="/dashboard/report/" element={<ReportDetails />} />

              <Route path=":id" element={<SingleReport />} />
            </Route>
          </Route>
        </Routes>
      </div>
    );
}
