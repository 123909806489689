import ModalWrapper from "components/common/ModalWrapper";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetSingleCategoryQuery } from "services/api";
import { deleteCategoryModal, openModal, updateCategoryModal } from "store/slice/commonSlice";
import DeleteCategory from "./child/DeleteCategory";
import UpdateCategory from "./child/UpdateCategory";

export default function SingleCategory() {
  
  const { id } = useParams();
  const dispatch = useDispatch()

  const openUpdateCategory = useSelector((state) => state.common.updateCategory);
  const openDeleteCategory = useSelector(state => state.common.deleteCategory)

  const { data, error, isLoading } = useGetSingleCategoryQuery(id);

  isLoading ? console.log("loading") : console.log(!error ? data : error);

  function handleUpdateModal() {
    dispatch(updateCategoryModal())
    dispatch(openModal())
  }

  function openDeleteCategoryModal() {
    dispatch(deleteCategoryModal())
    dispatch(openModal())
  }

  return (
    <div>
      <ModalWrapper>
        {openUpdateCategory && <UpdateCategory details={data && data.data} id={id} />}
        { openDeleteCategory && <DeleteCategory category={data && data.data.name} id={id} />}
      </ModalWrapper>
      <div className="mt-10">
        <h1 className="font-bold text-xl">Category Details</h1>

        <hr />
        <div className="flex justify-between">
          <div className="mt-5 space-y-2">
            <div className="w-40 h-40 rounded-md bg-slate-800 relative">
              
              <img
                src={
                  (data && data.data.image) ||
                  `https://source.unsplash.com/random/200x200`
                }
                className="object-cover w-full h-full rounded-md"
              />
            </div>
            <h1>
              Name: <span className="font-bold">{data && data.data.name}</span>
            </h1>
            <h1>
              Description:{" "}
              <span className="font-bold">{data && data.data.description}</span>
            </h1>
            <h1>
              Delete Status:{" "}
              <span className="font-bold">
                {data && String(data.data.isDelete)}
              </span>
            </h1>
          </div>

          {/* right details */}

          <div className="flex flex-col space-y-3 mt-5">
            <button
              className="bg-blue-100 border-blue-600 border-2 text-blue-900 px-5 py-1.5 rounded"
              onClick={handleUpdateModal}
            >
              Edit
            </button>
            <button className="bg-red-600 text-white px-5 py-1.5 rounded" onClick={openDeleteCategoryModal}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
