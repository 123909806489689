import useSelection from "antd/es/table/hooks/useSelection";
import React, { useRef, useState } from "react";
import { MdAddAPhoto } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  useAddNewCategoryMutation,
  useUpdateCategoryMutation,
} from "services/api";

export default function UpdateCategory({ details, id }) {
  

    const { name, description, code, image } = details
  
    const dispatch = useDispatch()

  const [detail, setDetail] = useState({
    name: name,
    description: description,
    code: code,
    image: image,
    preview: "",
    base64Img: ""
  });

  // refs
  const imageBrowseRef = useRef();

  // fetch query
  const [updateCategory] = useUpdateCategoryMutation();

  // *************************************************************************
  function inputChangeHandler(e) {
    const { name, value } = e.target;
    // console.log(name, value);
    setDetail({ ...detail, [name]: value });
  }

  const handleOpenImageBrowser = () => {
    imageBrowseRef.current.click();
  };

    function handleImageInputChange(e) {
      
        const file = e.target.files[0]

      setDetail({
          ...detail,
          image: e.target.files[0],
          preview: URL.createObjectURL(e.target.files[0]),
          
        });
        // console.log(e.target.files)
          const reader = new FileReader()
          let baseString;
          reader.onloadend = () => {
              baseString = reader.result;
              // console.log(baseString)
          }
        reader.readAsDataURL(file);
        // console.log(baseString)
  }

  // console.log(detail)

  // submitting data
  const handleSubmitAddCategory = (e) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    e.preventDefault();

    // console.log("send");
    // const formData = new FormData();

    const { name, description, code, image } = detail;
    // formData.append("name", name);
    // formData.append("description", description);
    // formData.append("code", code);
    // formData.append("image", image);

    //   for (const key in formData) {
    //      console.log(key.values)
    //   }

    //   updateCategory(id, name, description, code, image)
    updateCategory({
      id: id,
      name: name,
      description: description,
      image: image,
    })
      .unwrap()
      .then((data) => console.log("fulfilled", data))
      .catch((error) => console.log("rejected", error));
    
      setDetail({
          ...detail,
          name: "",
          description: "",
          code: "",
          image: []
      })
  };

  return (
    <div>
      <h1 className="font-bold text-xl">Edit Category</h1>

      <div className="mt-5 flex space-x-10">
        <form className="flex flex-col space-y-3">
          <input
            className="w-[350px] py-2 bg-transparent border border-slate-400 rounded-md placeholder-slate-300 px-2"
            placeholder="Category name"
            type="text"
            name="name"
            value={detail.name}
            onChange={inputChangeHandler}
          />
          <textarea
            rows={3}
            className="w-[350px] py-2 bg-transparent border border-slate-400 rounded-md placeholder-slate-300 px-2"
            placeholder="Enter category description"
            type="text"
            name="description"
            value={detail.description}
            onChange={inputChangeHandler}
          />
          <input
            className="w-[350px] py-2 bg-transparent border-slate-400 rounded-md placeholder-slate-300 px-2"
            placeholder="code"
            type="text"
            name="description"
            value={detail.code}
            readOnly
          />

          <button
            className="w-[350px] py-2 bg-amber-900 text-white rounded-md"
            onClick={handleSubmitAddCategory}
          >
            Update Category
          </button>
        </form>

        {/* image upload */}
        <div>
          <div
            className="hover:bg-gray-400 text-black bg-gray-200 w-16 h-16 rounded-lg flex justify-center items-center relative cursor-pointer"
            onClick={handleOpenImageBrowser}
          >
            <button className="absolute pointer-events-none">
              <MdAddAPhoto className=" text-2xl pointer-events-none" />
              {/* <MusicNoteIcon /> */}
            </button>
            <input
              type="file"
              accept="image/png, image/jpeg, .jpeg, .jpg, .png"
              name="file"
              ref={imageBrowseRef}
              onChange={handleImageInputChange}
              className="w-6 h-6 rounded-full bg-yellow-400 absolute opacity-0"
              style={{ display: "none" }}
            />
          </div>

          <small className="italic text-slate-500">
            {" "}
            Upload category picture
          </small>
          {detail.image && (
            <div className="mt-2 w-[105px] h-[105px] bg-amber-800 rounded relative">
              <img src={detail.image} className="rounded object-cover" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
