import React, { useState } from "react";
import { MdClose, MdDownload, MdPrint } from "react-icons/md";
import Modal from "react-modal";


export default function SingleReport() {
  const customStyles = {
    content: {
      inset: "50% 55%",
      // marginRight: "50%",
      transform: "translate(-50%, -50%)",
      width: "1000px",
      background: "#0D17204F",
      height: "760px",
      overflow: "scroll"
    },
  };

  const [openPdf, setOpenPdf] = useState(false);

  function openModal() {
    setOpenPdf(true);
  }

  function closeModal() {
    setOpenPdf(false);
  }

  function afterOpenModal() {
    console.log("Modal is open");
  }

  return (
    <div>
      <div
        className="w-full p-5 bg-white drop-shadow-md rounded-md flex flex-col my-5 hover:bg-amber-700 group"
        onClick={openModal}
      >
        <h1 className="text-slate-600 font-bold group-hover:text-white">
          TPL4500901
        </h1>
        <p className="text-slate-400 group-hover:text-slate-100">
          Harley-Davidson & Co.
        </p>
        <small className="group-hover:text-slate-100">Uganda</small>
      </div>

      <Modal
        isOpen={openPdf}
        onRequestClose={closeModal}
        style={customStyles}
        onAfterClose={afterOpenModal}
        ariaHideApp={false}
      >
        <div className="flex justify-end bg-black h-10">
          <div className="space-x-5 flex items-center">
            <button onClick={closeModal} className="text-white px-2.5">
              <MdDownload />
            </button>
            <button onClick={closeModal} className="text-white px-2.5">
              <MdPrint />
            </button>
            <button onClick={closeModal} className="text-white px-2.5">
              <MdClose />
            </button>
          </div>
        </div>
        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
          <div style={{ height: "750px" }}>
            <Viewer fileUrl="https://www.clickdimensions.com/links/TestPDFfile.pdf" />
          </div>
        </Worker> */}
        <img src="/pdf-sample.png" />
      </Modal>
    </div>
  );
}
