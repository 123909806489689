


import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import Modal from "react-modal";

export default function SingleAdmin() {


    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "450px",
      },
    };

    const [modalOpen, setModalOpen] = React.useState(false);

    function openModal() {
      setModalOpen(true);
    }

    function afterOpenModal() {
      console.log("after open modal");
    }

    function closeModal() {
      setModalOpen(false);
    }


    return (
      <div className="px-5">
        <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          style={customStyles}
          onAfterClose={afterOpenModal}
          ariaHideApp={false}
        >
          <div className="flex justify-end mb-10">
            <button onClick={closeModal}>x Close</button>
          </div>
          <div className="flex flex-col items-center space-y-5">
            <h1 className="text-2xl text-center font-semibold">
              Are you sure you want to perform this action?
            </h1>
            <button className="w-full bg-red-700 text-white py-2.5 rounded-lg">
              Yes, Delete
            </button>
            <button className="text-lg font-medium" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </Modal>

        <div className="mt-5 flex justify-between items-center">
          <h1>Admin Information</h1>
          <div className="flex space-x-5">
            <button>Update</button>
            <button
              className="px-10 py-1.5 rounded-lg text-red-500 flex items-center space-x-1"
              onClick={openModal}
            >
              <span>
                <MdDelete />
              </span>{" "}
              <p>Delete</p>
            </button>
          </div>
        </div>
        <hr className="border-slate-200 mt-1" />

        {/* user information */}

        <div className="flex mt-5">
          <img src="/user-profile.png" className="" />
          <div className="flex flex-col space-y-1.5 px-10 py-5">
            <h1 className="font-bold text-3xl">John Biko</h1>
            <p className="text-lg">Role: Admin</p>
          </div>
        </div>

        <div className="flex-col flex mt-10">
          <p>Date Joined</p>
          <p className="font-bold">23/04/2021</p>
        </div>

        <div className="flex-col flex mt-5">
          <p>Status</p>
          <p className="font-bold text-green-500">Active</p>
          <p className="text-sm italic text-slate-400">
            <small>Click to change status</small>
          </p>
        </div>
      </div>
    );
}
