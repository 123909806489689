


import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = `${process.env.REACT_APP_BASE_URL}`
const token = sessionStorage.getItem('afcfta-user')
const authHeader = {
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`
}

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  tagTypes: ["Category", "SingleCategory"],
  endpoints: (builder) => ({
    getSummary: builder.query({
      query: () => ({
        url: `/admin/dashboard`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    //   User endpoints
    getUsers: builder.query({
      query: () => ({
        url: `/admin/users`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getSingleUser: builder.query({
      query: (id) => ({
        url: `/admin/users/${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    // admin users
    getAdmin: builder.query({
      query: () => ({
        url: `/admin/users/adminusers`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getSingleAdmin: builder.query({
      query: (id) => ({
        url: `/admin/users/adminusers/`,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    //   categories
    getCategories: builder.query({
      query: () => ({
        url: `/categories`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ["Category"],
    }),
    getSingleCategory: builder.query({
      query: (id) => ({
        url: `/categories/${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ["SingleCategory"],
      // providesTags: (result, error, arg) => console.log("THis is coming from api:", result.data, "Error:", error, "Args:", arg)
      // providesTags: (result, error, arg) => result ? [...result.map(({ id }) => ({ type: 'Category', id: id }))] : 'Category'
    }),
    addNewCategory: builder.mutation({
      query: ({ name, description, code, image }) => ({
        url: `/admin/categories/create?name=${name}&description=${description}&code=${code}`,
        method: "POST",
        headers: {
          // "Content-type": "*/*",
          Authorization: `Bearer ${token}`,
        },
        // body: image
      }),
      invalidatesTags: ["Category", "SingleCategory"],
    }),
    updateCategory: builder.mutation({
      query: ({ id, name, description, image }) => ({
        url: `/admin/categories/update/${id}?name=${name}&description=${description}`,
        method: "PATCH",
        headers: {
          "Content-type": "*/*",
          Authorization: `Bearer ${token}`,
        },
      }),
      invalidatesTags: ["Category", "SingleCategory"],
    }),
    deleteCategory: builder.mutation({
      query: ({ id }) => ({
        url: `categories/${id}`,
        method: "DELETE",
        headers: {
          "Content-type": "*/*",
          Authorization: `Bearer ${token}`,
        },
      }),
      invalidatesTags: ["Category", "SingleCategory"],
    }),
  }),
});


export const {
    useGetSummaryQuery,
    useGetUsersQuery,
    useGetSingleUserQuery,
    useGetAdminQuery,
  useGetCategoriesQuery,
  useGetSingleCategoryQuery,
  useAddNewCategoryMutation,
  useUpdateCategoryMutation,
    useDeleteCategoryMutation
} = apiSlice