


import React from 'react'

export default function SearchInput() {
    return (
        <div>
            <input
                className="p-2 w-full rounded-lg border-2 border-x-amber-100"
                placeholder="Search user"
            />
        </div>
    )
}
