

import React from 'react'
import { Blocks } from 'react-loader-spinner';
import { Outlet } from 'react-router-dom'
import SearchInput from '../user/child/SearchInput';

export default function ReportIndex() {


    


    return (
      <div className="px-5">
        <div className="mt-10 mb-5 w-full">
          <SearchInput />
        </div>
        <Blocks
          visible={true}
          height="50"
          width="50"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
        />
        <Outlet />
      </div>
    );
}
