import { Skeleton } from "@mui/material";
import React, { useEffect } from "react";
import { FcBusinessman, FcHome } from "react-icons/fc";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetSummaryQuery } from "services/api";

export default function Summary() {
  const { data, error, isLoading } = useGetSummaryQuery();

  if (isLoading) {
    console.log("Loading data");
  }
  if (error) {
    console.log("main error", error);
  }

  if (data) {
    console.log("Summary data", data);
  }

  

  return (
    <div>
      {/* summary cards */}
      <div className="grid grid-cols-4 gap-y-5 gap-x-5 mt-20">
        {/* Total vendors */}
        <Link to={""}>
          <div className="bg-slate-50 p-2.5 rounded-md drop-shadow min-w-[200px]">
            <div className="flex justify-between space-x-11">
              <div className="space-y-1">
                <p className="text-base font-light">Total Vendors</p>
                <h1 className="text-3xl font-bold">
                  {data ? data.data.totalVendors : <Skeleton />}
                </h1>
              </div>
              {/* <div>{item.icon}</div> */}
            </div>
          </div>
        </Link>

        {/* Total customers */}
        <Link to={""}>
          <div className="bg-slate-50 p-2.5 rounded-md drop-shadow min-w-[200px]">
            <div className="flex justify-between space-x-11">
              <div className="space-y-1">
                <p className="text-base font-light">Total Customers</p>
                <h1 className="text-3xl font-bold">
                  {data ? data.data.totalCustomers : <Skeleton />}
                </h1>
              </div>
              {/* <div>{item.icon}</div> */}
            </div>
          </div>
        </Link>

        {/* Total Trades */}
        <Link to={""}>
          <div className="bg-slate-50 p-2.5 rounded-md drop-shadow min-w-[200px]">
            <div className="flex justify-between space-x-11">
              <div className="space-y-1">
                <p className="text-base font-light">Total Sales</p>
                <h1 className="text-3xl font-bold">
                  {data ? (
                    data.data.totalSales.goods + data.data.totalSales.services
                  ) : (
                    <Skeleton />
                  )}
                </h1>
              </div>
              {/* <div>{item.icon}</div> */}
            </div>
          </div>
        </Link>
        {/* total Orders */}
        <Link to={""}>
          <div className="bg-slate-50 p-2.5 rounded-md drop-shadow min-w-[200px]">
            <div className="flex justify-between space-x-11">
              <div className="space-y-1">
                <p className="text-base font-light">Total Orders</p>
                <h1 className="text-3xl font-bold">
                  {data ? (
                    data.data.totalOrders.goods + data.data.totalOrders.services
                  ) : (
                    <Skeleton />
                  )}
                </h1>
              </div>
              {/* <div>{item.icon}</div> */}
            </div>
          </div>
        </Link>
      </div>

      {/* Actions */}
      <div className="mt-10">
        <h4 className="mb-5">Actions</h4>
        <div className="grid grid-cols-4 gap-x-5 gap-y-5">
          <Link to={""}>
            <div className="bg-slate-50 p-2.5 rounded-md drop-shadow min-w-[200px]">
              <div className="flex justify-between space-x-11">
                <div className="space-y-1">
                  <p className="text-base font-light">View Vendors</p>
                  {/* <h1 className="text-3xl font-bold">{item.value}</h1> */}
                </div>
                {/* <div>{item.icon}</div> */}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

const testData = [
  {
    name: "Total Vendor",
    value: 9000,
    icon: <FcBusinessman className="text-6xl" />,
    link: "user",
  },
  {
    name: "Total Customers",
    value: 500,
    icon: <FcHome className="text-6xl" />,
    link: "user",
  },
  {
    name: "Total Trades",
    value: 4500,
    icon: <FcHome className="text-6xl" />,
    link: "report",
  },
  {
    name: "Total Listed Products",
    value: 4500,
    icon: <FcHome className="text-6xl" />,
    link: "",
  },
];

const testAction = [
  {
    name: "Create admin",
    value: 9000,
    icon: <FcBusinessman className="text-6xl" />,
    link: "admin",
  },
  {
    name: "View vendor",
    value: 500,
    icon: <FcHome className="text-6xl" />,
    link: "user",
  },
  {
    name: "View customers",
    value: 4500,
    icon: <FcHome className="text-6xl" />,
    link: "user",
  },
  {
    name: "View reports",
    value: 4500,
    icon: <FcHome className="text-6xl" />,
    link: "report",
  },
  // {
  //   name: "Products",
  //   value: 4500,
  //   icon: <FcHome className="text-6xl" />,
  //   link: "product",
  // },
  {
    name: "View Transactions",
    value: 4500,
    icon: <FcHome className="text-6xl" />,
    link: "transaction",
  },
];
