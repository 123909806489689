


import React, { useRef, useState } from 'react'
import { MdAddAPhoto } from 'react-icons/md';
import { useAddNewCategoryMutation } from 'services/api';


export default function AddCategory() {

    const [detail, setDetail] = useState({
      name: "",
      description: "",
      code: `C-${(Math.floor(Math.random() * 10000) + 10000)
        .toString()
        .substring(1)}`,
        image: [],
      preview: ""
    });

    // refs
    const imageBrowseRef = useRef()

    // fetch query
    const [addCategory] = useAddNewCategoryMutation()


    const token = sessionStorage.getItem('afcfta-user')


    // *************************************************************************

    function inputChangeHandler(e) {
      const { name, value } = e.target;
    //   console.log(name, value);
      setDetail({ ...detail, [name]: value });
    }



    function imageHandler(e) {
        console.log(e.target)
        // setDetail({
        //     ...detail, image: e.target.files
        // })
    }

    const handleOpenImageBrowser = () => {      
      imageBrowseRef.current.click();
    };

    function handleImageInputChange(e) {
        // console.log(e.target.files)
        setDetail({
          ...detail,
            image: e.target.files[0],
          preview: URL.createObjectURL(e.target.files[0])
        })
    }

    // console.log(detail)

    // submitting data
    const handleSubmitAddCategory = (e) => {
        const baseUrl = process.env.REACT_APP_BASE_URL
        e.preventDefault()

        console.log("send")
      const formData = new FormData();

      const { name, description, code, image } = detail;
    //   formData.append("name", name);
    //   formData.append("description", description);
    //   formData.append("code", code);
      
        const imageData  = new FormData()
        imageData.append("image", image);
        
        // try {
        //      fetch(`${baseUrl}/categories/create`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-type': 'application/json',
        //             Authorization: `Bearer ${token}`
        //         },
        //         body: (formData)
        //      })
        //         .then(data => data.json())
        //         .then(res => console.log("response", res))
            
        // } catch (error) {
        //     console.log(error)
        // }
        
      addCategory({name: name, description: description, code: code, image: imageData})
        .unwrap()
        .then((data) => console.log("fulfilled", data))
        .catch((error) => console.log("rejected", error));
    };

    return (
      <div>
        <h1 className="font-bold text-xl">Add New Category</h1>

        <div className="mt-5 flex space-x-10">
          <form className="flex flex-col space-y-3">
            <input
              className="w-[350px] py-2 bg-transparent border border-slate-400 rounded-md placeholder-slate-300 px-2"
              placeholder="Category name"
              type="text"
              name="name"
              value={detail.name}
              onChange={inputChangeHandler}
            />
            <textarea
              rows={3}
              className="w-[350px] py-2 bg-transparent border border-slate-400 rounded-md placeholder-slate-300 px-2"
              placeholder="Enter category description"
              type="text"
              name="description"
              value={detail.description}
              onChange={inputChangeHandler}
            />
            <input
              className="w-[350px] py-2 bg-transparent border-slate-400 rounded-md placeholder-slate-300 px-2"
              placeholder="code"
              type="text"
              name="description"
              value={detail.code}
              readOnly
            />

            <button
              className="w-[350px] py-2 bg-amber-900 text-white rounded-md"
              onClick={handleSubmitAddCategory}
            >
              Add Category
            </button>
          </form>

          {/* image upload */}
          <div>
            <div
              className="hover:bg-gray-400 text-black bg-gray-200 w-16 h-16 rounded-lg flex justify-center items-center relative cursor-pointer"
              onClick={handleOpenImageBrowser}
            >
              <button className="absolute pointer-events-none">
                <MdAddAPhoto className=" text-2xl pointer-events-none" />
                {/* <MusicNoteIcon /> */}
              </button>
              <input
                type="file"
                accept="image/png, image/jpeg, .jpeg, .jpg, .png"
                name="file"
                ref={imageBrowseRef}
                onChange={handleImageInputChange}
                className="w-6 h-6 rounded-full bg-yellow-400 absolute opacity-0"
                style={{ display: "none" }}
              />
            </div>

            <small className="italic text-slate-500">
              {" "}
              Upload category picture
            </small>
            {detail.preview && (
              <div className="mt-2 w-[105px] h-[105px] bg-amber-800 rounded relative">
                <img
                  src={detail.preview}
                  className="rounded object-cover"
                />
                
              </div>
            )}
          </div>
        </div>
      </div>
    );
}
