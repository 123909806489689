import axios from "axios";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetCategoriesQuery } from "services/api";

export default function CategoryList() {
  const navigate = useNavigate();

  function handleRowClick(id) {
    navigate(`/dashboard/product/${id}`);
  }

  const { data, error, isLoading } = useGetCategoriesQuery();

  // isLoading ? console.log("loading") : console.log(!error ? data : error)

  const token = sessionStorage.getItem("afcfta-user");

  // useEffect(() => {
  //   category()

  //   return () => {

  //   }
  // }, [])

  const category = async () => {
    try {
      const res = fetch(`${process.env.REACT_APP_BASE_URL}/categories`, {
        method: "GET",
        cors: "no-cors",
      });
      // console.log(await res)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mt-5">
      <h1 className="text-xl font-bold">Product Categories</h1>

      
      {/* list of categories */}
      <div className="mt-5">
        <div className="grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3">
          {data &&
            data.data.map((category, i) => (
              <div className="flex-shrink-0">
                <Link
                  key={i}
                  to={`/dashboard/category/${category.id}`}
                  state={{
                    categoryName: category.name,
                  }}
                >
                  <div
                    className="flex flex-col space-y-3 justify-center items-center flex-shrink-0"
                    onClick={() => handleRowClick(category.id)}
                  >
                    <img
                      src={category.image}
                      className="w-20 h-20 md:w-28 md:h-28 rounded-xl border-[0.5px] border-black flex-shrink-0"
                    />
                    <div className="">
                      <div className="ml-3">
                        <p className="capitalize font-black text-sm leading-tight w-20 text-center truncate">
                          {category.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}

          {/* </div>
                <div
                  key={`category-${category.id}`}
                  className="bg-slate-100 h-[80px] flex items-center space-x-3 rounded-md drop-shadow-lg cursor-pointer"
                  onClick={() => handleRowClick(category.id)}
                >
                  <div className="w-1/4 rounded-l-md relative">
                    <img
                      src={category.image}
                      className="object-cover h-[80px] rounded-l-md relative "
                    />
                  </div>
                  <p
                    key={i}
                    className="capitalize font-bold "
                  >
                    {category.name}
                  </p>
                </div> */}
        </div>
      </div>
    </div>
  );
}
