const { createSlice } = require("@reduxjs/toolkit");



const initialState = {
    modalState: false,
    // category state
    newCategory: false,
    updateCategory: false,
    deleteCategory: false,
    
}

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        AddCateogryModal(state) {
            // state.modalState = true;
            state.newCategory = true;
            state.updateCategory = false
            state.deleteCategory = false
    },
    updateCategoryModal(state) {
            // state.modalState = true;
        state.updateCategory = true;
        state.deleteCategory = false;
        state.newCategory = false
        },
        deleteCategoryModal(state) {
            state.deleteCategory = true
            state.newCategory = false;
            state.updateCategory = false
    },
    openModal(state) {
      state.modalState = true;
    },
    closeModal(state) {
      state.modalState = false;
    },
  },
});


export const {openModal, closeModal, AddCateogryModal, updateCategoryModal, deleteCategoryModal} = commonSlice.actions

export default commonSlice.reducer