

import React from 'react'
import { Outlet } from 'react-router-dom'
import SearchInput from './child/SearchInput'

export default function UserIndex() {
    return (
      <div>
        <div className="mt-10 w-full px-5">
          <SearchInput />
        </div>
        <Outlet />
      </div>
    );
}
